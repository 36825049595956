###*
# @ngdoc object
# @name lpaIncidentTracking.controller:IncidentTrackingCtrl
#
# @description
#
###
class IncidentTrackingCtrl
  ### @ngInject ###
  constructor: (
    $scope
    $state
    $stateParams
    Incidents
    $rootScope
    $log
    $window
    MundoSocket
    MundoMap
    $timeout
    $interval
    LpaMapUtils
    Proposals
    _
    ProposalList
    CommentsList
    ProposalManager
    RestUtils
    MapSync
    Tasks
    Statuses
    DispatchUnitTypes
    Routes
    ProposalRouteManager
  ) ->

    # ----------------------------------------------------------
    # INITIALISTATION
    # ----------------------------------------------------------

    @incidents = {}
    @incident = {}
    @features = {}
    @proposals = {}
    @acceptedProposals = {}
    @proposalFeatures = {}
    @oldProposalFeatures = {}
    @statuses = {}
    @tasks = {}
    @dispatchUnitTypes = {}
    @incidentTaskType = {}
    @unitTasks = {}
    @incidentTasks = {}
    @proposalRefresher = {}
    @unitStates = {}

    # Get the incident id from the URL
    @incidentId = $stateParams['incident_id']

    @proposalIncrement = 5
    @proposalAmount = @proposalIncrement

    # How much time before the window refreshes automatically
    $scope.time = 60000

    # Default state of the info window
    @detailsGroupIsOpen = true

    # Override some params/functions of MundoMap
    @map = MundoMap.createInstance 'dispatch-map',
      view:
        maxZoom: 17
        zoom: 13
      follow:
        objects: []
        enabled: true
        speedZoom: false
        zoomLevel: 15
      search:
        enabled: false

    # States
    @State =
      PRISTINE: 0
      PROGRESS: 1
      SUCCESS: 2
      ERROR: 4

    # Initalise all the layers and set the appropriate styles
    @incidentLayer = MundoMap.getLayerById(@map, 'incidents')
    @trailLayer = MundoMap.getLayerById(@map, 'trails')
    @routesLayer = MundoMap.getLayerById(@map, 'routes')
    @vehicleLayer = MundoMap.getLayerById(@map, 'markers')

    LpaMapUtils.setIncidentLayerStyle @incidentLayer
    LpaMapUtils.setTrailsLayerStyle @trailLayer
    LpaMapUtils.setProposalVehicleLayerStyle @vehicleLayer
    LpaMapUtils.setRoutesLayerStyle @routesLayer

    # Add a popup overlay
    @popupOverlay = new ol.Overlay.Popup
      insertFirst: false
    @map.addOverlay @popupOverlay

    # Enable the updating of the map and track one specific incident
    MapSync.incidents.enable @map, @features, @incidents, @incidentLayer,
      incident: $stateParams['incident_id']
      listen: ['incident_create', 'incident_update', 'incident_close']
      close: true
      window: $window
      onUpdate: (oldIncident, updatedIncident) =>
        if oldIncident.location != updatedIncident.location
          @updateProposals()

    MapSync.units.enable
      map: @map
      markers: @features
      statuses: @statuses
      markerLayer: @vehicleLayer
      filter: @applyUnitFilters
      visibleUnits: []
      trailLayer: @trailLayer
      automaticallyAddNewStatuses: false

    $scope.$on MundoSocket.options.unitStatusConsumer.dataEventName, (event, data) =>
      status = data.event
      unitId = status.unit.id

      # Attempt to determine assigned incident ID
      statusIncidentId = null
      statusTaskId = null
      try
        statusTaskId = status.unit.dispatchUnit.task.id
        statusIncidentId = status.unit.dispatchUnit.task.incident.id
      catch e

      # If our incident ID matches, continue
      if statusIncidentId == @incidentId
        # If the unit has just now been assigned to this incident, mark it as dispatched
        proposal = @proposals[unitId]
        feature = @proposalFeatures[unitId]

        if proposal? or feature?
          # feature.set '_proposal', null
          delete @proposalFeatures[unitId]
          delete @proposals[unitId]

          @oldProposalFeatures[unitId] = feature
          feature.set '_proposal', null
          feature.set '_cachedStyle', null

          RestUtils.getFullList Tasks,
            "filter[]": [
              "id,#{statusTaskId}"
            ]
          .then (results) =>
            task = if results.length then results[0] else null
            if task?
              @incidentTasks[task.id] = task
              @acceptedProposals[task.id] = task
              task.state = @State.SUCCESS
      else
        angular.forEach @acceptedProposals, (proposal) =>
          # Check if the dispatch unit of the proposal matches the one in the received status message
          if (proposal.dispatchUnit? and status.unit.dispatchUnit?) and
          (proposal.dispatchUnit.id == status.unit.dispatchUnit.id)

            # If the dispatch unit has a new task, update proposals
            if statusTaskId != proposal.id
              $log.debug "Unit #{status.unit.dispatchUnit.label} has a new task, updating proposals"
              @shouldUpdateProposals = true

        # Loop over all current proposals
        angular.forEach @proposals, (proposal) =>
          # Check if the dispatch unit of the proposal matches the one in the received status message
          if (proposal.dispatchUnit? and status.unit.dispatchUnit?) and
          (proposal.dispatchUnit.id == status.unit.dispatchUnit.id)

            if (statusTaskId? and (not @unitTasks[status.unit.id]?)) or
            ((not statusTaskId?) and @unitTasks[status.unit.id]?) or
            ((statusTaskId? and @unitTasks[status.unit.id]?) and (statusTaskId != @unitTasks[status.unit.id]))
              $log.debug "Unit task no longer matches for unit #{status.unit.dispatchUnit.label}, updating proposals"
              @unitTasks[status.unit.id] = statusTaskId
              @shouldUpdateProposals = true

        if @shouldUpdateProposals
          @updateProposals()

    @updateProposals = () =>
      @shouldUpdateProposals = false
      @proposalFeatures = {}
      @oldProposalFeatures = {}
      # @trailLayer.getSource().clear()
      @routesLayer.getSource().clear()
      @loadProposals(true)

    # Load the initial incident

    @loadIncident = () =>

      RestUtils.getFullList DispatchUnitTypes
        .then (results) =>
          angular.forEach results, (dus) =>
            @dispatchUnitTypes[dus.id] = dus

      ProposalManager.getIncidentTaskType().then (proposalsResults) =>
        # Get the incident dispatch task type from the database
        if proposalsResults.count is 0
          $log.warn "NO INCIDENT TASK TYPE"
        else
          @incidentTaskType = proposalsResults[0]

      @incidentPromise = RestUtils.getFullList Incidents,
        'filter[]': [
            "id," + @incidentId
          ]

      @incidentPromise.then (result) =>
        @incident = result[0]
        LpaMapUtils.addIncidentToMap @incident, @features, @incidentLayer, @incidents

    @loadProposals = (reload = false) =>
      @proposals = {}
      @acceptedProposals = {}

      # Get Incident tasks and check which Statuses are already assigned
      @dispatchUnitTaskPromise = RestUtils.getFullList Tasks,
        'filter[]': [
            "incident.id," + $stateParams['incident_id']
            "taskType.code,INCIDENT"
            "closedAt,NULL"
            "incident.closedAt,NULL"
          ]

      # Get the already dispatched units
      @dispatchUnitTaskPromise.then (tasks) =>
        angular.forEach tasks, (task) =>
          task.state = @State.SUCCESS
          @acceptedProposals[task.id] = task
        if reload || @isEmptyObject @acceptedProposals
          @showProposals()
          @isAlreadyLoaded = true
        else
          unitIds = []
          for key, x of @acceptedProposals
            if x.dispatchUnit && x.dispatchUnit.unit
              unitIds.push x.dispatchUnit.unit.id

          RestUtils.getFullList Statuses,
            'filter[]': [
              'OR,unit.id,EQ+' + unitIds.join ',unit.id,EQ+'
            ]
          .then (statuses) =>
            angular.forEach statuses, (status) =>
              if status.unit.dispatchUnit? and status.unit.dispatchUnit.task?
                @unitTasks[status.unit.id] = status.unit.dispatchUnit.task.id
              LpaMapUtils.addStatusToMap status, @features, @vehicleLayer, @statuses, null, null, @trailLayer
              @addLocationToUnit status, @acceptedProposals
            @addTimeToProposals()
          angular.forEach @acceptedProposals, (task) =>
            if task.dispatchUnit
              @incidentTasks[task.id] = task
              MapSync.units.addVisible task.dispatchUnit.unit.id
          @addTimeToProposals()
          @showTasks()

          ## Show status on map and sync

    @addLocationToUnit = (status, proposals) ->
      angular.forEach proposals, (task) ->
        if task && task.dispatchUnit && task.dispatchUnit.unit &&
        status && status.unit && task.dispatchUnit.unit.id == status.unit.id
          task.dispatchUnit.unit[location] = status.unit.location

    @getIncident = (proposal, index, propsals, param) ->
      Incidents.one(proposal.incident.id).get().then (result) ->
        propsals[index][param] = result

    @isEmptyObject = (list) ->
      return Object.keys(list).length == 0

    @showProposals = () =>
      # Get proposals and show them on the map
      Proposals.post
        incident: $stateParams['incident_id']
        amount: @proposalAmount
      .then (results) =>
        visibleUnits = _.map results.results, 'unit.id'

        if visibleUnits.length == 0
          visibleUnits = [false]

        MapSync.units.visible = visibleUnits

        if results.results.length > 0
          MundoMap.disableFollow @map

        unitIds = _.map results.results, (proposal) ->
          proposal.unit.id

        dispatchUnitIds = _.map results.results, (proposal) ->
          proposal.dispatchUnit.id

        angular.forEach results.results, (proposal) =>
          proposal.state = @State.PRISTINE

          if proposal.unit
            proposal.isProposal = true
            # Show the route on the map
            @proposals[proposal.unit.id] = proposal
            LpaMapUtils.addProposalToMap proposal, null, @routesLayer, @proposalFeatures

        # Get all related statuses
        StatusPromise = RestUtils.getFullList Statuses,
          'filter[]': [
            "OR,unit.id,EQ+" + unitIds.join ',unit.id,EQ+'
          ]
        StatusPromise.then (statuses) =>

          @map.updateSize()
          angular.forEach statuses, (status) =>
            # loop all assets of a status
            # Cache all statuses in an array
            @statuses[status.unit.id] = status

            if status.unit.dispatchUnit? and status.unit.dispatchUnit.task?
              @unitTasks[status.unit.id] = status.unit.dispatchUnit.task.id

            @addProposalStatusToMap status, @proposals[status.unit.id]
            # Load the tasks of this dispatch unit
            @addLocationToUnit status, @acceptedProposals
            @addLocationToUnit status, @proposals

          @panToFeatures @proposalFeatures
          @addTimeToProposals()

        dispatchUnitTaskPromise = RestUtils.getFullList Tasks,
          'filter[]': [
              "OR,dispatchUnit.id,EQ+" + dispatchUnitIds.join ',dispatchUnit.id,EQ+'
              "taskType.code,INCIDENT"
              "closedAt,null"
              "incident.closedAt,NULL"
            ]
        dispatchUnitTaskPromise.then (results) =>
          angular.forEach results, (task) =>
            @unitTasks[task.dispatchUnit.unit.id] = task.id
            # @proposals[task.dispatchUnit.unit.id]['incident'] = task.incident
            @addTimeToProposals()
            # @getIncident(task, task.dispatchUnit.unit.id, @proposals, 'previousIncident')

        # Get the tasks currently attached to the incident
        incidentTaskPromise = RestUtils.getFullList Tasks,
          'filter[]': [
            "taskType.code,INCIDENT"
            "incident.id,#{@incidentId}"
            "closedAt,null"
            "incident.closedAt,NULL"
          ]
        incidentTaskPromise.then (results) =>
          unitIds = (x.dispatchUnit.unit.id for x in results)
          RestUtils.getFullList Statuses,
            'filter[]': [
              'OR,unit.id,EQ+' + unitIds.join ',unit.id,EQ+'
            ]
          .then (statuses) =>
            angular.forEach statuses, (status) =>
              LpaMapUtils.addStatusToMap status, @features, @vehicleLayer, @statuses, null, null, @trailLayer

          angular.forEach results, (task) =>
            @incidentTasks[task.id] = task
            MapSync.units.addVisible task.dispatchUnit.unit.id
          @showTasks()

    @loadMoreProposals = () =>
      # the REST call accepts a max of 100
      if @proposalAmount <= 95
        @proposalAmount += @proposalIncrement
      else
        @showMore = false
      @updateProposals()

    @showTasks = () =>
      angular.forEach @incidentTasks, (task) =>
        #MapSync.addVisible task.unit.id
        routePromise = RestUtils.getFullList Routes,
          'filter[]': [
            "task.id,EQ #{task.id}"
          ]
        routePromise.then (results) =>
          angular.forEach results, (route) =>
            if route.route?
              MapSync.units.addVisible route.unit.id
              @oldProposalFeatures[route.unit.id] = LpaMapUtils.addRouteToMap route.route, null, @routesLayer

    # ----------------------------------------------------------
    # ACTIONS
    # ----------------------------------------------------------

    # Dispatch a dispatchunit to an incident (GPS)
    @createTask = (unitId) =>
      proposal = @proposals[unitId]

      if !proposal?
        return

      if proposal.state != @State.PRISTINE
        return

      proposal.state = @State.PROGRESS
      ProposalManager.createTask proposal, @incidentTaskType
        .then (task) =>
          if task.id?
            @incidentTasks[task.id] = task
            @tasks[unitId] = task
            proposal.state = @State.SUCCESS
          else
            proposal.state = @State.PRISTINE
        , (error) =>
          $log.warn "Task could not be created for Dispatch Unit with ID '#{unitId}'", error
          proposal.state = @State.PRISTINE

    # ----------------------------------------------------------
    # UI ACTIONS / TOGGLES
    # ----------------------------------------------------------

    # Toggle the little window on the bottom left with the incident details
    @toggleDetails = (ev) =>
      ev.stopPropagation()
      @detailsGroupIsOpen = !@detailsGroupIsOpen

    # Show the comments/logs of this incident in a popup
    @showComments = () ->
      CommentsList.show($stateParams['incident_id'])

    # Do an actual complete page reload
    @reload = () ->
      location.reload()

    # Open a new vehicle tracking window
    @openFastTrackVehicleWindow = (status) ->
      url = $state.href('fastTracking', {status_id: status['_id']}, {absolute: true})
      openedWindow = window.open url, 'uniqueName' + status['_id'], 'width=1100,height=800', false
      # This return is added because else we have a console error
      # Coffeescript changes the 'window.open' to 'return window.open()' what generates a console error
      return true

    # Open a new vehicle incident tracking window
    @openIncidentWindow = (incident) ->
      url = $state.href('incidentTracking', {incident_id: incident['id']}, {absolute: true})
      openedWindow = window.open url, 'uniqueName' + incident['id'], 'width=1100,height=800', false
      # This return is added because else we have a console error
      # Coffeescript changes the 'window.open' to 'return window.open()' what generates a console error
      return true

    # ----------------------------------------------------------
    # MAP FUNCTIONALITY
    # ----------------------------------------------------------

    # Handle click events on the map
    @map.on "click", (e) =>
      @map.forEachFeatureAtPixel e.pixel, (f, l) =>
        layerProps = l.getProperties()
        featureProps = f.getProperties()

        if layerProps._layerId is "markers"
          if featureProps.features.length == 1
            angular.forEach featureProps.features, (feature)->
              LpaMapUtils.openFastTrackVehicleWindow feature.getProperties()._status
          else
            extent = ol.extent.createEmpty()
            angular.forEach featureProps.features, (feature)->
              ol.extent.extend extent, feature.getGeometry().getExtent()
              # Define the nice animations for panning and zooming
            pan = ol.animation.pan
              duration: 500
              source: @map.getView().getCenter()

            zoom = ol.animation.zoom
              duration: 500
              resolution: @map.getView().getResolution()

            @map.beforeRender(pan)
            @map.beforeRender(zoom)

            @map
              .getView()
              .fit extent, @map.getSize(),
                padding: [100, 100, 100, 100]
                constrainResolution: true
        if layerProps._layerId is "incidents"
          @openIncidentWindow featureProps._incident

    # Set a highlighted proposal. It changes the style mostly
    @highlightProposal = (proposal = null) =>
      proposalUnitId = if proposal? then proposal.unit.id else null

      # Highlight the
      if proposal?
        proposal.highlighted = true

      # Unset the cached style of all features.
      for unitId, feature of @proposalFeatures
        feature.set '_cachedStyle', null

        highlight = switch proposalUnitId
          when unitId then true
          when null then null
          else false

        feature.set '_highlight', highlight

        if highlight == true
          @panToFeatures [feature]

    @unhighlightProposal = (proposal) =>
      proposal.highlighted = false
      @highlightProposal null

    # Function to pan the map to a list of specific features
    @panToFeatures = (features) =>
      if features?
        extent = ol.extent.createEmpty()

        angular.forEach features, (feature) ->
          ol.extent.extend extent, feature.getGeometry().getExtent()

        pan = ol.animation.pan
          duration: 400
          source: @map.getView().getCenter()

        zoom = ol.animation.zoom
          duration: 400
          resolution: @map.getView().getResolution()

        @map.beforeRender(pan)
        @map.beforeRender(zoom)
        @map
          .getView()
          .fit extent, @map.getSize(),
            padding: [100, 100, 100, 100]
            constrainResolution: true

    # ----------------------------------------------------------
    # HELPERS
    # ----------------------------------------------------------

    @addProposalStatusToMap = (status, proposal) =>
      LpaMapUtils.addStatusToMap status, @features, @vehicleLayer, @statuses, proposal, null, @trailLayer

    # Helper function to get the keys of an object
    @count = (object) ->
      return Object.keys(object).length

    @acceptedProposalsTimer = () =>
      $interval(() =>
        @addTimeToProposals()
      , 30000)

    @addTimeToProposals = () =>
      angular.forEach @acceptedProposals, (proposal) =>
        if @statuses[proposal.dispatchUnit.unit.id]
          statusLocation = @statuses[proposal.dispatchUnit.unit.id].location.point.coordinates
          from = statusLocation[0].toString() + ',' + statusLocation[1].toString()
          to = proposal.incident.locationLongitude.toString() + ',' + proposal.incident.locationLatitude.toString()
          ProposalRouteManager
          .getRoute(from, to).then (route) =>
            if route && route[0] && route[0].paths && route[0].paths[0]
              status = @statuses[proposal.dispatchUnit.unit.id]
              proposal.timeToIncident = route[0].paths[0].time
              status.timeToIncident = route[0].paths[0].time
              LpaMapUtils.addStatusToMap status, @features, @vehicleLayer, @statuses, null, null, @trailLayer

    @loadIncident()
    @loadProposals()
    @acceptedProposalsTimer()

angular
  .module 'lpaIncidentTracking'
  .controller 'IncidentTrackingCtrl', IncidentTrackingCtrl
